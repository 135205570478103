import { Controller } from '@hotwired/stimulus';

class FilterController extends Controller {
  connect() {
    this.oldValues = {};
    this.isFirstSelection = true;

    // Get all filter elements that have the `data-filter-name` attribute
    const filterElements = this.element.closest('form').querySelectorAll('[data-filter-name]');

    filterElements.forEach((element) => {
      if (element.dataset.thisCheck === 'true') {
        this.oldValues[element.dataset.filterName] = element.value;
      }
    });

    const form = this.element.closest('form');
    this.applyButton = form.querySelector('[data-apply-button]');
    this.downloadButton = form.querySelector('[data-download-button] a');

    if (this.applyButton) {
      this.applyButton.addEventListener('click', this.handleApplyButtonClick.bind(this));
    }

    if (this.element.dataset.dateType) {
      this.element.addEventListener('changeDate', this.handleDateChange.bind(this));
    } else {
      this.element.addEventListener('change', this.handleValueChange.bind(this));
    }

    this.checkDownloadButtonState();
  }

  checkDownloadButtonState() {
    const isDownloadButtonEnabled = localStorage.getItem('downloadButtonEnabled') === 'true';

    const isAnySpecifiedFieldEmpty = Object.values(this.oldValues).some((value) => !value);

    this.toggleDownloadButton(isDownloadButtonEnabled && !isAnySpecifiedFieldEmpty);
  }

  handleDateChange(event) {
    const newValue = event.target.value;
    this.updateOldValue(newValue);
  }

  handleValueChange(event) {
    const newValue = event.target.value;
    this.updateOldValue(newValue);
  }

  updateOldValue(newValue) {
    this.toggleDownloadButton(false);
    localStorage.setItem('downloadButtonEnabled', 'false');

    if (newValue !== undefined && newValue !== null) {
      this.oldValues[this.element.dataset.filterName] = newValue;
    }
  }

  handleApplyButtonClick() {
    const filterElements = this.element.closest('form').querySelectorAll('[data-filter-name]');
    let filtersChanged = false;

    filterElements.forEach((element) => {
      if (element.value !== this.oldValues[element.dataset.filterName]) {
        filtersChanged = true;
      }
      this.oldValues[element.dataset.filterName] = element.value;
    });

    if (filtersChanged) {
      localStorage.setItem('downloadButtonEnabled', 'true');
    }

    this.toggleDownloadButton(true);

    const form = this.element.closest('form');
    form.submit();
  }

  toggleDownloadButton(isEnabled) {
    if (this.downloadButton) {
      if (isEnabled) {
        this.downloadButton.classList.remove('disabled');
        this.downloadButton.removeAttribute('disabled');
      } else {
        this.downloadButton.classList.add('disabled');
        this.downloadButton.setAttribute('disabled', true);
      }
    }
  }
}

export default FilterController;
