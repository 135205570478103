import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

function addDay(dateStr) {
  // Split the date string into day, month, and year
  let [day, month, year] = dateStr.split('/');

  // Create a Date object (JavaScript uses MM/DD/YYYY format)
  let date = new Date(`${month}/${day}/${year}`);

  // Add 1 day to the date
  date.setDate(date.getDate() + 1);

  // Format the new date back to DD/MM/YYYY
  let newDay = String(date.getDate()).padStart(2, '0');
  let newMonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  let newYear = date.getFullYear();

  // Combine into the desired format
  return `${newDay}/${newMonth}/${newYear}`;
}

// Function to convert time string to a Date object (same day)
function parseTime(timeStr) {
  let [hours, minutes] = timeStr.split(':');
  let date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  return date;
}

export default class extends Controller {
  show(event) {
    const row = event.currentTarget;

    const unformattedDate = row.dataset.unformattedDate;

    let modalElement = document.getElementById('timesheetModal');

    const workingShiftDateField = modalElement.querySelector('#workingShiftDateField');
    workingShiftDateField.value = unformattedDate;
    const workingShiftDateFieldData = modalElement.querySelector('#workingShiftDateFieldData');
    const dashFormat = unformattedDate.split('/');
    workingShiftDateFieldData.value = `${dashFormat[2]}-${dashFormat[1]}-${dashFormat[0]}`;

    const attendanceStartDateField = modalElement.querySelector('#attendanceStartDateField');
    const attendanceStartDateFieldUI = modalElement.querySelector('#attendanceStartDateFieldUI');

    const attendanceEndDateField = modalElement.querySelector('#attendanceEndDateField');
    const attendanceEndDateFieldUI = modalElement.querySelector('#attendanceEndDateFieldUI');

    attendanceStartDateField.value = unformattedDate;
    attendanceStartDateFieldUI.value = unformattedDate;
    attendanceEndDateField.value = unformattedDate;
    attendanceEndDateFieldUI.value = unformattedDate;

    const overTimeStartAtDateField = modalElement.querySelector('#overTimeStartDateField');
    const overTimeStartAtDateFieldUI = modalElement.querySelector('#overTimeStartDateFieldUI');

    const overTimeEndAtDateField = modalElement.querySelector('#overTimeEndDateField');
    const overTimeEndAtDateFieldUI = modalElement.querySelector('#overTimeEndDateFieldUI');

    overTimeStartAtDateField.value = unformattedDate;
    overTimeStartAtDateFieldUI.value = unformattedDate;
    overTimeEndAtDateField.value = unformattedDate;
    overTimeEndAtDateFieldUI.value = unformattedDate;

    const currentShift = modalElement.querySelector('#workingShiftUI');
    currentShift.value = row.dataset.currentWorkingShiftId;

    const currentShiftId = modalElement.querySelector('#workingShiftId');
    currentShiftId.value = row.dataset.currentWorkingShiftId;

    const leaveStartDateField = modalElement.querySelector('#leaveStartDateField');
    const leaveEndDateField = modalElement.querySelector('#leaveEndDateField');
    leaveStartDateField.value = unformattedDate;
    leaveEndDateField.value = unformattedDate;

    const attendanceStartTimeField = modalElement.querySelector('#attendanceStartTimeField');
    const attendanceEndTimeField = modalElement.querySelector('#attendanceEndTimeField');

    attendanceEndTimeField.addEventListener('input', function () {
      if (attendanceStartTimeField.value && attendanceEndTimeField.value) {
        if (parseTime(attendanceStartTimeField.value) > parseTime(attendanceEndTimeField.value)) {
          const newDate = addDay(attendanceStartDateFieldUI.value);
          attendanceEndDateField.value = newDate;
          attendanceEndDateFieldUI.value = newDate;
        } else {
          attendanceEndDateField.value = attendanceStartDateFieldUI.value;
          attendanceEndDateFieldUI.value = attendanceStartDateFieldUI.value;
        }
      }
    });

    const overTimeStartTimeField = modalElement.querySelector('#overTimeStartTimeField');
    const overTimeEndTimeField = modalElement.querySelector('#overTimeEndTimeField');
    overTimeEndTimeField.addEventListener('input', function () {
      if (overTimeStartTimeField.value && overTimeEndTimeField.value) {
        if (parseTime(overTimeStartTimeField.value) > parseTime(overTimeEndTimeField.value)) {
          const newDate = addDay(overTimeStartAtDateFieldUI.value);
          overTimeEndAtDateField.value = newDate;
          overTimeEndAtDateFieldUI.value = newDate;
        } else {
          overTimeEndAtDateField.value = overTimeStartAtDateFieldUI.value;
          overTimeEndAtDateFieldUI.value = overTimeStartAtDateFieldUI.value;
        }
      }
    });

    let modal = new Modal(modalElement, {
      backdrop: true,
      keyboard: true,
    });
    modal.show();
  }
}
