import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static targets = ['form'];

  showConfirm(event) {
    event.preventDefault();

    // เก็บ form ที่จะถูก submit ไว้
    this.currentForm = event.currentTarget.closest('form');

    // open confirm modal but not close the previous modal
    const confirmModal = new Modal(document.getElementById('confirmModal'), {
      backdrop: true,
      keyboard: true,
    });
    confirmModal.show();

    // manage backdrop stacking for multiple modals
    // Adjust z-index for backdrop stacking
    document.querySelectorAll('.modal-backdrop').forEach((backdrop, index) => {
      if (index === 1) {
        backdrop.classList.add('modal-backdrop--stack');
      }
    });

    if (this.element.dataset.timesheetConfirmType === 'dayOff') {
      document.getElementById('workingShiftId').value = '';
    } else {
      document.getElementById('workingShiftId').value =
        document.getElementById('workingShiftUI').value;
    }

    // กำหนด event ให้กับปุ่มใน modal
    document.getElementById('confirmButton').addEventListener('click', () => {
      confirmModal.hide();
      console.log('submitting form');
      this.currentForm.submit();
    });
  }
}
